import style from "./Index.module.scss";
import LogicFlow from "@logicflow/core";
import {
  NodeIcon,
  DeleteIcon,
  UndoIcon,
  RedoIcon,
  UploadIcon,
  LoadIcon,
  FitScreenIcon,
} from "../Icon";
import { useState, useEffect } from "react";
import LocalStorageService from "../../services/localStorageService";
import FileExporter from "../../services/fileExporter";
import FlowChartPersistenceService, {
  STORAGE_KEY,
} from "../../services/flowChartPersistenceService";

interface Props {
  logicFlow?: LogicFlow;
  onAddNodeMouseDown: () => void;
}

export default function ControlPanel({ logicFlow, onAddNodeMouseDown }: Props) {
  const localStorageService = new LocalStorageService();
  const fileExporter = new FileExporter();
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);

  useEffect(() => {
    const onElementClicked = (e: any) => {
      const data = e.data || e.detail.data;
      if (data.type === "start-node" || data.type === "stop-node") {
        setShowDeleteButton(false);
        return;
      }
      setShowDeleteButton(true);
    };

    const onBlankClicked = () => {
      setShowDeleteButton(false);
    };

    logicFlow?.on("element:click", onElementClicked);
    logicFlow?.on("blank:click", onBlankClicked);
    window.addEventListener("element:click", onElementClicked);
    return () => {
      logicFlow?.off("element:click", onElementClicked);
      logicFlow?.off("blank:click", onBlankClicked);
      window.removeEventListener("element:click", onElementClicked);
    };
  }, [logicFlow]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && logicFlow) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result;
        const parsedData = JSON.parse(content as string);
        const flowChartPersistenceService = new FlowChartPersistenceService(
          logicFlow
        );
        flowChartPersistenceService.setData(parsedData);
      };

      reader.readAsText(file);
    }
  };

  return (
    <div className={style.Container}>
      <div className={style.Button} onMouseDown={onAddNodeMouseDown}>
        <NodeIcon />
        Add Node
      </div>
      {showDeleteButton && (
        <div
          className={style.Button}
          onClick={() => {
            deleteSelectedElements(logicFlow);
          }}
        >
          <DeleteIcon />
          Delete
        </div>
      )}
      <div
        className={style.Button}
        onClick={() => {
          logicFlow?.undo();
        }}
      >
        <UndoIcon />
        Undo
      </div>
      <div
        className={style.Button}
        onClick={() => {
          logicFlow?.redo();
        }}
      >
        <RedoIcon />
        Redo
      </div>

      <div className={style.Divider}></div>

      <div
        className={style.Button}
        onClick={() => {
          exportIni(localStorageService, STORAGE_KEY, fileExporter);
        }}
      >
        <LoadIcon />
        Export .ini
      </div>
      <div
        className={style.Button}
        onClick={() => {
          logicFlow?.fitView();
        }}
      >
        <FitScreenIcon />
        Fit Screen
      </div>
    </div>
  );
}

const exportData = (
  localStorageService: LocalStorageService,
  dataKey: string,
  fileExporter: FileExporter
) => {
  const data = localStorageService.getItem(dataKey);
  fileExporter.exportJSON(data, "flow.json");
};

const deleteSelectedElements = (logicFlow?: LogicFlow) => {
  if (!logicFlow) {
    return;
  }

  const elements = logicFlow.getSelectElements(true);
  logicFlow.clearSelectElements();
  elements.edges.forEach((edge) => logicFlow.deleteEdge(edge.id));
  elements.nodes.forEach((node) => {
    if (node.type === "item-node") {
      logicFlow.deleteNode(node.id);
    }
  });
};

const exportIni = (
  localStorageService: LocalStorageService,
  dataKey: string,
  fileExporter: FileExporter
) => {
  const data = localStorageService.getItem(dataKey);
  fileExporter.exportINI(data, "flow.ini");
};
