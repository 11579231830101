export default class FileExporter {
  exportJSON(data: any, filename: string): void {
    const jsonContent = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonContent], {
      type: "application/json;charset=utf-8;",
    });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  exportINI(data: any, filename: string): void {
    let startId = null;
    let stopId = null;
    const nodeMap: { [key: string]: any } = data.nodes.reduce(
      (map: { [x: string]: any }, node: any) => {
        if (node.type === "start-node") {
          startId = node.id;
        } else if (node.type === "stop-node") {
          stopId = node.id;
        }

        map[node.id] = node;
        return map;
      },
      {}
    );

    const edgeMap: { [key: string]: string } = data.edges.reduce(
      (map: { [key: string]: string }, node: any) => {
        map[node.sourceNodeId] = node.targetNodeId;
        return map;
      },
      {}
    );

    let ini = "";
    let nextId: string | null = startId;
    while (nextId && nextId != stopId) {
      nextId = edgeMap[nextId];
      const node = nodeMap[nextId];
      if (!node || node.type !== "item-node") {
        break;
      }

      ini += `[${node.properties.name}]\r\nEnable = 1\r\nType = ${node.properties.type}\r\nErrorCode = ${node.properties.errorCode}\r\n\n`;
    }

    console.log(ini);

    const blob = new Blob([ini], {
      type: "application/ini;charset=utf-8;",
    });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
