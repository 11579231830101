import style from "./ItemNode.module.scss";
import { ReactNodeProps } from "@logicflow/react-node-registry";
import FlowNode from "../node/Index";
import { ItemIcon, ArrowLeftIcon, ArrowRightIcon } from "../Icon";
import CardHeader from "../node/header/Index";
import { useRef, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

interface Props extends ReactNodeProps {
  children?: React.ReactNode;
}

export default function ItemNode({ node, graph, children }: Props) {
  let properties = node.getProperties();
  const nodeRef = useRef(null);
  const [title, setTitle] = useState(properties.name);
  const [showDetail, setShowDetail] = useState<boolean>(false);

  useEffect(() => {
    node.setProperty("name", title);
  }, [title]);

  return (
    <FlowNode node={node} graph={graph}>
      <div className={style.CardContent}>
        <CardHeader
          Icon={ItemIcon}
          title={title}
          onChange={setTitle}
        ></CardHeader>
        <div
          className={style.ArrowIcon}
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          {showDetail ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </div>
      </div>

      <CSSTransition
        in={showDetail}
        nodeRef={nodeRef}
        timeout={3000}
        classNames="fade"
        unmountOnExit
      >
        <div className={style.Container} ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </FlowNode>
  );
}
