import style from "./Index.module.scss";
import { useEffect, useState, useRef } from "react";
import DataBox from "../Index";

interface Props {
  label: string;
  Icon: React.ElementType;
  value: number | string;
  onChange: (newValue: any) => void;
  formatter?: (value: any) => string;
  type?: string;
  placeholder?: string;
}

export default function InputFieldDataBox({
  label,
  Icon,
  value,
  onChange,
  formatter,
  type,
  placeholder,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={style.Container}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <DataBox
        Icon={Icon}
        label={label}
        value={formatter ? formatter(value) : value.toString()}
      />
      {isOpen && (
        <div className={style.Editor}>
          <div className={style.Header}>
            <Icon />
            <div>{label}</div>
          </div>
          <input
            type={type || "number"}
            value={value}
            onChange={(e) => {
              if (!e.target.value) {
                onChange(0);
                return;
              }

              onChange(parseFloat(e.target.value));
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        </div>
      )}
    </div>
  );
}
