import style from "./Index.module.scss";
import { ReactNodeProps } from "@logicflow/react-node-registry";
import ItemNode from "../ItemNode";
import { ModulationIcon, ExecutionModeIcon } from "../../Icon";
import Form from "../../form/Index";
import SelectorDataBox from "../../data-box/selector/Index";
import InputFieldDataBox from "../../data-box/input/Index";
import { useState, useEffect } from "react";

export default function KittTestNode({ node, graph }: ReactNodeProps) {
  let properties = node.getProperties();
  const [type, setType] = useState<string>(properties.type || "0");
  const [errorCode, setErrorCode] = useState<string>(
    properties.errorCode || "AEPP100"
  );

  useEffect(() => {
    node.setProperty("type", type);
  }, [type]);

  useEffect(() => {
    node.setProperty("errorCode", errorCode);
  }, [errorCode]);

  return (
    <ItemNode node={node} graph={graph}>
      <div className={style.Detail}>
        <Form title="Parameters">
          <SelectorDataBox
            Icon={ModulationIcon}
            label="Type"
            value={type}
            options={["0", "1", "2", "3", "4"]}
            onChange={setType}
          />
          <InputFieldDataBox
            type="text"
            Icon={ExecutionModeIcon}
            label="Error Code"
            value={errorCode}
            onChange={setErrorCode}
            placeholder="Error Code"
          />
        </Form>
      </div>
    </ItemNode>
  );
}
