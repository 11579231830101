import "./App.css";
import LogicFlow from "@logicflow/core";
import "@logicflow/core/dist/index.css";
import { useState, useEffect, useRef } from "react";
import { register } from "@logicflow/react-node-registry";
import StartNode, { StartNodeModel } from "./components/StartNode";
import StopNode, { StopNodeModel } from "./components/StopNode";
import KittTestNode from "./components/item-node/kitt-test-node/Index";
import ItemNodeModel from "./components/item-node/ViewModel";
import ControlPanel from "./components/control-panel/Index";
import SavedToast from "./components/saved-toast/Index";
import ErrorToast from "./components/error-toast/Index";
import NotSupported from "./components/not-supported/Index";
import FlowChartPersistenceService from "./services/flowChartPersistenceService";

function App() {
  const refContainer = useRef<HTMLDivElement>(null);
  const [logicFlow, setLogicFlow] = useState<LogicFlow | undefined>();
  const [showSavedToast, setShowSavedToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleDragItem = () => {
    logicFlow?.dnd.startDrag({
      type: "item-node",
    });
  };

  useEffect(() => {
    if (isMobileDevice()) {
      return;
    }

    if (!refContainer.current) {
      return;
    }

    const lf = new LogicFlow({
      container: refContainer.current,
      grid: true,
      textEdit: false,
      edgeType: "bezier",
    });

    register(
      {
        type: "start-node",
        component: StartNode,
        model: StartNodeModel,
      },
      lf
    );

    register(
      {
        type: "stop-node",
        component: StopNode,
        model: StopNodeModel,
      },
      lf
    );

    register(
      {
        type: "item-node",
        component: KittTestNode,
        model: ItemNodeModel,
      },
      lf
    );

    lf.on("connection:not-allowed", (data) => {
      if (data.msg) {
        setErrorMessage(data.msg);
        setShowErrorToast(true);

        const timer = setTimeout(() => {
          setShowErrorToast(false);
        }, 3000);

        return () => clearTimeout(timer);
      }
    });

    const persistenceService = new FlowChartPersistenceService(lf);
    persistenceService.setOnAutoSaveListener(() => {
      setShowSavedToast(true);

      const timer = setTimeout(() => {
        setShowSavedToast(false);
      }, 3000);

      return () => clearTimeout(timer);
    });
    setLogicFlow(lf);
  }, [refContainer]);

  return (
    <div className="App">
      <div className="LogicFlowView" ref={refContainer}>
        <NotSupported />
      </div>

      {!isMobileDevice() && (
        <div className="ControlPanel">
          <ControlPanel
            logicFlow={logicFlow}
            onAddNodeMouseDown={handleDragItem}
          />
        </div>
      )}

      <SavedToast show={showSavedToast} />
      <ErrorToast show={showErrorToast} message={errorMessage} />
    </div>
  );
}

export default App;

function isMobileDevice(): boolean {
  return (
    "ontouchstart" in window ||
    /android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase())
  );
}
